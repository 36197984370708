// extracted by mini-css-extract-plugin
export var warningBackgroundlight = "g_mV";
export var warningBackgrounddark = "g_mW";
export var warningBackgroundcustom = "g_mX";
export var bottom = "g_gH";
export var ribbon = "g_mY";
export var modal = "g_mZ";
export var bottomRight = "g_m0";
export var btnWrapper = "g_d2 d_bD d_bP d_bJ";
export var contentWrapper = "g_m1";
export var container = "g_m2";
export var titleWrapper = "g_m3 d_bD d_bP d_dw";
export var elementWrapper = "g_m4 d_bD d_bP d_dw";
export var cookieButtons = "g_m5 d_bz d_bD d_bP d_bJ";
export var btn = "g_m6 d_bz d_bD d_bP";
export var link = "g_m7";
export var decline = "g_m8 g_m6 d_bz d_bD d_bP";
export var hoverStyle = "g_m9";
export var zoom = "g_nb";
export var row = "g_nc";