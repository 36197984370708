// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "q_qz d_bD";
export var storyRowWrapper = "q_hx d_hx d_bK";
export var storyLeftWrapper = "q_qB d_bz d_bP";
export var storyWrapperFull = "q_qC d_cD";
export var storyWrapperFullLeft = "q_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "q_m1 d_hy";
export var storyLeftWrapperCenter = "q_qD d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "q_qF d_hF";
export var storyHeader = "q_qG d_hD d_w d_cs";
export var storyHeaderCenter = "q_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "q_hB d_hB d_by d_dw";
export var storyBtnWrapper = "q_qH d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "q_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "q_qJ d_fg d_Z";
export var imageWrapperFull = "q_qK d_w d_H d_bf d_Z";
export var SubtitleSmall = "q_qd s_qd s_qM s_qY";
export var SubtitleNormal = "q_qf s_qf s_qM s_qZ";
export var SubtitleLarge = "q_qg s_qg s_qM s_q0";
export var textLeft = "q_dv";
export var textCenter = "q_dw";
export var textRight = "q_dx";